<template>
    <div class="animated fadeIn">
        <CCard>
            <CCardHeader><font-awesome-icon icon="list-ul"/> Departments</CCardHeader>
            <CCardBody>
                <CDataTable
                    :items="department_list"
                    :fields="department_list_fields"
                    :table-filter="{ placeholder: 'Your text here.', label: 'Search:'}"
                    :items-per-page="10"
                    border
                    items-per-page-select
                    sorter
                    pagination
                    :loading="this.table_loading"
                >
                <template slot="no-items-view" v-if="this.table_loading">
                    <center>
                        <CSpinner
                            style="width:4rem;height:4rem;"
                            color="info"
                        />
                    </center>
                </template>
                <template #description="{item}">
                    <td>
                        {{item.description ? item.description : ''}}
                    </td>
                </template>
                <template #Action="{item}">
                    <td>
                        <router-link :to="{path: `/${$store.getters['getOrganizationName']}/administrator/department/${item.id}`}">
                            <CButton 
                                color="info" 
                                shape="pill" 
                                size="sm"
                            >
                                <font-awesome-icon icon="cog"/> Manage
                            </CButton>
                        </router-link>
                    </td>
                </template>
                <template #status="{item}">
                    <td>
                        <CBadge :color="getColorStatus(item.status)">
                            {{item.status}}
                        </CBadge>
                    </td>
                </template>
                </CDataTable>
            </CCardBody>
        </CCard>   
    </div>
</template>
<script>
export default {
    name: 'DepartmentLists',
    data(){
        return {
            table_loading: true,
            department_list: []
        }
    },
    created() {
        this.getDepartments();
        this.$emit('activeTab', 0);
    },
    computed: {
        department_list_fields: function () {
            let custom_fields= [
                { key: 'name', label: 'Name'},
                { key: 'description' },
                { key: 'head' },
                { key: 'status' },
            ]
            if(this.$store.getters.can('view-department')) {
                custom_fields.push({ key: 'Action', sorter: false})
            }
            return custom_fields;
        }
    },
    methods: {
        getDepartments: function () {
            this.$Progress.start()
            axios.get('/departments/list', {validateStatus: () => true}).then(response => {
                if(response.status == 200) {
                    for (let i = 0; i < response.data.data.length; i++) {
                        let head_name = response.data.data[i].head ? response.data.data[i].head.first_name + ' ' + response.data.data[i].head.last_name : '';
                        let row = {
                                id:  response.data.data[i].id, 
                                name:  response.data.data[i].name, 
                                description: response.data.data[i].description, 
                                status: response.data.data[i].deleted_at ? 'Archived' : 'Active', 
                                head: head_name, 
                        }
                        this.department_list.push(row)
                    }
                    this.table_loading = false;
                    this.$Progress.finish()
                }
            })
        },
    }
}
</script>